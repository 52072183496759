const m = require("mithril")
import about from "../static/about.html"
import aboutnew from "../static/aboutnew.html"
import privacy from "../static/privacy.html"
import sources from "../static/sources.html"
import archive from "../static/archive.html"
import Fragment from "./Fragment.jsx"
import Header from "./Header.jsx"

// The Static component returns some static HTML inside a template. It reads the
// current route to decide which content to show. It currently assumes that all
// static content lives inside the same template, which is coded at the bottom.
module.exports = {
    view: function (vnode) {
        let page = m.route.get()
        let content = ""

        switch (page) {
            case "/about":
                content = m.trust(about)
                break;
            case "/aboutnew":
                content = m.trust(aboutnew)
                break;
            case "/privacy":
                content = m.trust(privacy)
                break;
            case "/sources":
                content = m.trust(sources)
                break;
            case "/archive":
                content = m.trust(archive)
                break;
            default:
                console.log(`unknown static page: ${page}`)
                m.route.set('/')
                return
        }

        return (<>
            <Header />
            <div class="static-content">{content}</div>
            <div class="static-home-link pt-20 ml-10 pb-10 text-sm italic font-medium"><m.route.Link href="/">← Back to home page</m.route.Link></div>
            <Fragment src="footer" />
        </>)
    }
}
