const m = require("mithril")
import header from "../fragments/header.html"
import footer from "../fragments/footer.html"
import homehero from "../fragments/homehero.html"

// The Fragment component returns some static HTML that is usable inside another
// component. See HomeHero.jsx for a simple example. To add more fragments to
// the project, add them to the import list up top and the switch statement down
// below.
module.exports = {
    view: function (vnode) {
        let src = vnode.attrs.src
        switch (src) {
            case "header":
                return m.trust(header)
            case "footer":
                return m.trust(footer)
            case "homehero":
                return m.trust(homehero)
            default:
                return (<div>unknown fragment: {src}</div>)
        }
    }
}
