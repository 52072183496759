const m = require("mithril")

const colormap = {
    "Environment": "bg-teal-100 text-slate-800 ",
    "Sleep": "bg-violet-200 text-slate-800 ",
    "Diet": "bg-sky-150 text-slate-800 ",
    "Exercise": "bg-yellow-100 text-slate-800 ",
    "Multi": "bg-gradient-to-r from-rose-100 via-teal-100 to-indigo-100 text-slate-800 ",
    "Recommendations": "bg-slate-550 text-white ",
    "Observations": "bg-gradient-to-r from-slate-700 via-slate-800 to-slate-700 text-slate-100 ",
    "n": "bg-teal-200 text-transparent",
    "l": "bg-violet-250 text-transparent",
    "i": "bg-cyan-250 text-transparent",
    "x": "bg-yellow-100 text-transparent",
    "u": "bg-gradient-to-r from-rose-300 via-teal-200 to-violet-250 text-transparent",
    "b": "bg-slate-50 text-transparent",
    "e": "bg-slate-350 text-transparent",
    "nv": "bg-teal-200 md:bg-gradient-to-r md:from-teal-200 md:via-teal-200 md:to-teal-50 text-transparent",
    "le": "bg-violet-250 md:bg-gradient-to-r md:from-violet-250 md:via-violet-250 md:to-violet-50 text-transparent",
    "ie": "bg-sky-200 md:bg-gradient-to-r md:from-sky-200 md:via-sky-200 md:to-sky-50 text-transparent",
    "xe": "bg-yellow-200 md:bg-gradient-to-r md:from-yellow-200 md:via-yellow-200 md:to-yellow-50 text-transparent",
    "ul": "bg-gradient-to-b from-rose-300 via-teal-200 to-indigo-250 text-transparent",
    "nvi": "bg-teal-200 md:bg-gradient-to-r md:from-teal-200 md:via-teal-200 md:to-teal-50 text-transparent",
    "lee": "bg-violet-250 md:bg-gradient-to-r md:from-violet-250 md:via-violet-250 md:to-violet-50 text-transparent",
    "iet": "bg-sky-200 md:bg-gradient-to-r md:from-sky-200 md:via-sky-200 md:to-sky-50 text-transparent",
    "xer": "bg-yellow-200 md:bg-gradient-to-r md:from-yellow-200 md:via-yellow-200 md:to-yellow-50 text-transparent",
    "ult": "bg-gradient-to-r from-rose-200 via-teal-200 to-indigo-100 text-transparent",
    "nt": "bg-teal-100 text-transparent tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold 2xs:font-bold",
    "ep": "bg-violet-200 text-transparent tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold 2xs:font-bold",
    "et": "bg-sky-150 text-transparent tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold 2xs:font-bold",
    "se": "bg-yellow-100 text-transparent tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold 2xs:font-bold",
    "ti": "bg-gradient-to-r from-rose-100 via-teal-100 to-indigo-100 text-transparent tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold 2xs:font-bold",
}

const sizemap = {
    "page-cat": "border border-slate-700 h-8 w-44 xs:w-48 py-2 text-[11px] 2xs:text-[11px] xs:text-[11px] md:text-[12px] rounded-lg shadow-sm tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold ",
    "page-typ": "border border-slate-700 h-8 w-44 xs:w-48 py-2 text-[11px] 2xs:text-[11px] xs:text-[11px] md:text-[12px] rounded-lg shadow-sm tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold ",
    "page-tag": "border border-slate-500 h-8 w-44 xs:w-48 py-2 text-[11px] 2xs:text-[11px] xs:text-[11px] md:text-[12px] rounded-lg shadow-sm tracking-wider 2xs:tracking-wide font-[Montserrat] font-semibold bg-slate-50 ",
    "list-cat": "mt-2 pt-1 h-6 w-36 sm:w-36 md:w-40 font-[Montserrat] text-[9px] 2xs:text-[10px] 2xs:tracking-widest border border-slate-600 rounded-lg ",
    "list-typ": "mt-2 pt-1 h-6 w-36 2xs:w-40 sm:w-36 md:w-40 font-[Montserrat] text-[9px] 2xs:text-[10px] 2xs:tracking-widest border border-slate-600 rounded-lg ",
    "quad-cat": "w-52 2xs:w-56 xs:w-auto sm:w-36 md:w-52 lg:w-56 xl:w-64 2xl:w-80 font-medium text-center text-[10px] 2xs:text-[11px] md:text-[12px] lg:text-[13px] xl:text-[14px] border border-slate-700 sm:rounded-tr-lg font-semibold ",
    "quad-sub": "w-52 2xs:w-56 xs:w-auto sm:w-40 md:w-52 lg:w-56 xl:w-64 2xl:w-80 font-medium text-center text-[10px] 2xs:text-[11px] md:text-[12px] lg:text-[13px] xl:text-[14px] border border-slate-700 bg-gray-200 sm:rounded-bl-lg font-semibold ",
    "tag-cat": "grow 2xs:grow-0 2xs:w-auto border-x border-slate-550 sm:border-y sm:border-slate-600 font-semibold text-center tracking-wide text-[11px] sm:text-[11.5px] lg:text-[13px] xl:text-[14px] 2xs:py-1 xs:py-0.5 sm:pb-0.5 sm:pt-1.5 md:pb-0.5 lg:pb-0 ",
    "tag-sub": "grow xs:w-auto font-medium text-center tracking-wide text-[11px] sm:text-[11.5px] lg:text-[13px] xl:text-[14px] bg-gray-200 rounded-b-md sm:rounded-none sm:rounded-bl-lg sm:border-y sm:border-sky-950 font-semibold py-0.5 2xs:py-1.5 xs:py-1 sm:pb-0.5 sm:pt-1.5 lg:pb-0 ",
}

{/* original label width  */}

// The Label component returns a colored label.
module.exports = {
    view: function (vnode) {
        let bgcolor = colormap[vnode.children] || ""
        let passedSize = vnode.attrs["size"] || "md"
        let sizetag = sizemap[passedSize]
        return m("div", 
            { "class": sizetag + " " + bgcolor },
            vnode.children
        )
    }
}
