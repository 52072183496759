const m = require("mithril")
import Label from "./Label.jsx"

module.exports = {
    view: function (vnode) {
        let c = vnode.attrs.card
        return (
            <div class="bg-sky-950 px-1">
            <div class="flex flex-row justify-between" style="margin-bottm: 0.5px;">
            <div>
            {/* t-l-h */}
            <div class="flex flex-row-reverse space-x-0.5 justify-start items-end truncate overflow-hidden" style="margin-left: 4px; margin-bottom: 1px;">
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px; margin-left: 2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.30px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.60px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.90px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 5.20px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 5.50px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.80px;width: 5.80px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 6.10px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 6.40px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full -rotate-12 truncate overflow-hidden" style="height: 2.15px;width: 6.70px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div>
            </div>
            {/* t-r-h 
            hidden 2xs show = 6
            */}
            <div class="flex justify-end mr-1 rounded-r-xl">
                  <div class="flex flex-row space-x-0.5 justify-end items-end truncate overflow-hidden" style="padding-right: 1.5px; margin-bottom: 1px;">
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.00px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.30px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.60px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 4.90px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 5.20px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 5.50px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.80px;width: 5.80px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 6.10px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 6.40px; margin-bottom: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full rotate-12 truncate overflow-hidden" style="height: 1.95px;width: 6.70px; margin-bottom: 0.6px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>
                </div>

            <div class="flex flex-row bg-sky-950">

            <div class="flex flex-col justify-between">
            <div>
                {/* t-l-v */}
            <div class="flex rounded-r-xl" style="">
                  <div class="flex flex-col space-y-0.5 items-end truncate overflow-hidden" style="margin-left: 3.2px; margin-bottom: 3px;">
                  <div class="rounded-full truncate overflow-hidden" style="height: 4.6px;width: 3.2px; margin-left: 0.8px; margin-bottom: 0.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.8px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>
                </div>

                {/* b-l-v */}
                <div>
                <div class="flex rounded-r-xl" style="">
                  <div class="flex flex-col-reverse space-y-0.5 items-end truncate overflow-hidden" style="margin-left: 3.2px; margin-top: 3.5px;">
                  <div class="rounded-full -rotate-12 truncate overflow-hidden" style="height: 3.8px;width: 3.2px; margin-left: 1.5px; margin-right: 0.3px; margin-top: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px; margin-right: 0.5px; margin-bottom: 1.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px; margin-right: 0.5px; margin-top: 2.0px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px; margin-right: 0.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px; margin-right: 0.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px; margin-right: 0.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px; margin-right: 0.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                </div></div>
                </div>
                </div>
                
                <m.route.Link class="w-full px-2 pt-1 pb-2 2xs:pb-3 rounded-md border-b bg-gradient-to-t from-slate-50 via-slate-100 to-slate-100 border-sky-950 shadow-md hover:border-gray-50 hover:shadow-xl font-semibold tracking-wider text-gray-800 whitespace-nowrap overflow-hidden" href={`/card/${c.id}`}>

                <div class="flex flex-row-reverse rounded-lg px-2 items-center space-x-0.5 bg-sky-950">
                </div>
                
                <div class="flex flex-col sm:flex-row mt-0.5">
                <div class="basis-full md:basis-5/6 shadow-sm shadow-slate-100 font-[Montserrat] text-[14px] xs:text-[15px] lg:text-[16px] xl:text[18px] 2xl:text-[21px] font-bold 2xs:font-semibold lg:font-bold tracking-wider text-slate-600 border-l-2 border-t border-b border-slate-50 bg-gradient-to-r from-slate-100 rounded-sm 2xs:pt-1 xs:pb-0.5 pl-1 pr-0.5 whitespace-nowrap overflow-hidden overflow-ellipsis">{c.note}</div>
                <div class="hidden md:flex flex-row grow-0 text-center mr-1.5 ml-4 pl-0.5 pt-0">
                    <Label size="list-typ">{c.typ}</Label>
                </div>    
                </div>

                <div class="flex flex-col sm:flex-row">

                <div class="shadow-sm shadow-slate-100 flex flex-row basis-full 2xs:basis-5/6 border-l-2 border-t border-b border-slate-100 bg-gradient-to-r from-slate-50 via-slate-100 to-transparent rounded-sm pt-1 pb-0.5 pl-1 items-end w-96 whitespace-nowrap overflow-hidden overflow-ellipsis">
                <div class="pl-0.5 pb-0.5 pt-0.5 sm:pb-0 lg:pt-1 text-[12px] xs:tracking-wider sm:text-[13px] lg:text-[15px] xl:text-[16px] font-sans font-bold lg:font-bold text-slate-400 md:text-slate-500">{c.src}: </div>
                <div class="pb-0.5 sm:pb-0 sm:pt-2 lg:pt-1 text-[12px] xs:tracking-wider sm:text-[13px] lg:text-[15px] xl:text-[16px] lg:tracking-wide ml-1 font-sans font-semibold lg:font-bold lg:font-semibold text-slate-400 whitespace-nowrap overflow-hidden overflow-ellipsis">{c.sub} </div>
                </div>
                
                <div class="hidden md:flex flex-row grow-0 text-center sm:mr-1.5 space-x-1 sm:ml-2 md:ml-4 pl-1 xs:pl-0.5 pt-0">
                    <Label size="list-cat">{c.cat}</Label>
                </div>

                <div class="flex flex-row md:hidden grow-0 text-center space-x-1 sm:ml-2 pl-1 xs:pl-0.5 pt-0 sm:pt-1">
                    <Label size="list-typ">{c.typ}</Label>
                    <Label size="list-cat">{c.cat}</Label>
                </div>
                
                
                </div>

            </m.route.Link>

            <div class="flex flex-col justify-between">
                {/* t-r-v */}
            <div class="flex mr-0.5 pb-0.5 rounded-r-xl" style="background:transparent">
                  <div class="flex flex-col space-y-0.5 items-start truncate overflow-hidden" style="margin-left: 1px;">
                  <div class="rounded-full truncate overflow-hidden" style="height: 4.7px;width: 3.2px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.8px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>

                {/* b-r-v */}
                <div class="flex mr-0.5 pt-1 rounded-r-xl" style="margin-top: 4px;">
                  <div class="flex flex-col-reverse space-y-0.5 items-start truncate overflow-hidden" style="margin-top: 3px; margin-left: 1.5px">
                  <div class="rounded-full rotate-12 truncate overflow-hidden" style="height: 3.8px;width: 3.2px; margin-top: 1px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px; margin-left: 0.5px; margin-bottom: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px; margin-left: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px; margin-left: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px; margin-left: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px; margin-left: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px; margin-left: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                </div></div>

                </div>
            
                </div>
            
            <div class="flex flex-row justify-between">
                {/* b-l-h */}
                <div class="flex justify-end mr-1 rounded-r-xl mb-4">
                  <div class="flex flex-row space-x-0.5 justify-end items-start truncate overflow-hidden" style="padding-left: 7px; margin-top: 1px;">
                  <div class="rounded-full rotate-12 truncate overflow-hidden" style="height: 1.95px;width: 4.3px; margin-left: 1px; margin-bottom: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 4.2px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.1px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.80px;width: 4.0px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 3.9px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.70px;width: 3.8px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.65px;width: 3.7px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.60px;width: 3.6px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.55px;width: 3.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.50px;width: 3.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.45px;width: 3.5px"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.40px;width: 3.5px; margin-top: 0.1px;"><Label>{c.typ.slice(1,2)}</Label></div>
                </div></div>
                {/* b-r-h */}
                <div class="flex justify-end mr-1 rounded-r-xl mb-4">
                  <div class="flex flex-row space-x-0.5 justify-end items-start truncate overflow-hidden" style="padding-right: 3.2px; margin-top: 1px;">      
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.65px;width: 3.7px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.70px;width: 3.8px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 3.9px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.80px;width: 4.0px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.1px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 4.2px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.95px;width: 4.3px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.00px;width: 4.4px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="rounded-full -rotate-12 xs:rotate-0 truncate overflow-hidden" style="height: 2.05px;width: 4.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 2.10px;width: 4.6px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 2.15px;width: 4.7px;"><Label>{c.typ.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full -rotate-12 truncate overflow-hidden" style="height: 2.20px;width: 4.8px; margin-bottom: 0.5px;"><Label>{c.typ.slice(1,2)}</Label></div>
                </div></div>

                </div>
            

                
            
                
                
                </div>
        )
    }
}